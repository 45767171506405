import "./src/styles/global.css";
import React from "react";
import { ThemeProvider } from "./src/contexts/theme-context";
import smoothscroll from "smoothscroll-polyfill";

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>;
};

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }
};
